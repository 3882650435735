<template>
  <div>
    <div v-if="videoIdentStore.token">
      <div class="hreg--card-info">
        <div class="h_iframe" v-if="videoIdentStore.idnowIdentUrl">
          <!-- Event listener is removed from the component, due to new SonarQube rule -->
          <!-- I added JS Code instead to handle the event -->
          <iframe
            title="video identification"
            id="iframe"
            style="height:2600px;"
            width="100%"
            :src="videoIdentStore.idnowIdentUrl"
            allow="camera;microphone"
            ref="iframeRef"
          >
          </iframe>
        </div>
        <div v-else-if="videoIdentStore.idnowError">
          <h3>{{ $t("videoIdentification.errorTitle") }}</h3>
          <p>{{ $t("videoIdentification.errorText") }}</p>
          {{ videoIdentStore.idnowError }}
        </div>
        <div v-else>
          {{$t('videoIdentification.loading')}}
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t("furtherInformation.tokenExpiredText") }}
    </div>
  </div>
</template>

<script setup>

import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();
appStore.activeStep = appStore.screenNumbers.videoIdent;
appStore.header = "videoIdentification.title";

import { useVideoIdentStore } from "@/store/VideoIdentStore";
const videoIdentStore = useVideoIdentStore();

if (!videoIdentStore.token) {
  appStore.header = "furtherInformation.tokenExpiredTitle";
}
else {
  videoIdentStore.fetchIdnowIdentUrl();
}

import { onMounted, ref } from "vue";
const iframeRef = ref(null);


onMounted(() => {
  if (iframeRef.value) {
    iframeRef.value.onload = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    };
  }
});
</script>
