import { useAgwManagerService } from "@/services/AgwManagerService";

export function validators(errors, unsetErrors) {
  const agwManagerService = useAgwManagerService();
  return {
    autocomplete: (event, inputOptions) => {
      // language does not matter, here. They must all
      // have the same keys.
      const value = event.target.value;
      if (value && inputOptions.de.some(item => item.key === value)) {
        unsetErrors(errors, event)
      }
    },
    pattern: (event, pattern) => {
      if (new RegExp(pattern).test(event.target.value)) {
        unsetErrors(errors, event);
      }
    },
    required: (event) => {
      if (event.target.value && event.target.value.length > 0) {
        unsetErrors(errors, event);
      }
    },
    fromOptions: (event, options) => {
      if (event.target.value && options.includes(event.target.value)) {
        unsetErrors(errors, event);
      }
    },
    backend: async (event, section) => {
      const path = `/body/${section}/${event.target.name}`
      const data = await agwManagerService.fetchJson(
        "/productfinder/validate-path",
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            path: path,
            value: event.target.value,
          }),
        }
      );
      if (data?.result && data.result === 'ok') {
        unsetErrors(errors, event);
      }
      return data?.result && data.result === 'ok';
    },
    checked: (event) => {
      if (event.target.value) {
        unsetErrors(errors, event);
      }
    },
    phone: async (event, countryValue) => {
      const data = await agwManagerService.fetchJson(
        `/productfinder/validate-phone?phone=${event.target.value}&country=${countryValue}`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
      if (data?.result && data.result === 'ok') {
        unsetErrors(errors, event);
      }
      return data?.result && data.result === 'ok';
    }
  }
}

