<template>
  <div class="container-fluid">
    <div class="row mb-4" v-if="membersStore.hasPraxisSoftware">
      <div class="col-lg-6">
        <div class="col-lg">
          <Autocomplete
            name="dropdown"
            :label="$t('emailForm.praxisSoftwareLabel')"
            :error="error"
            :suggestions="praxisSoftwareOptions"
            :language="appStore.currentLanguage.toLowerCase()"
            v-model="emailStore.praxisSoftwareSelect"
            initialValue="emailForm.noPraxisSoftware"
            @done="selected => emailStore.changePlaceholder(membersStore.associationSelection,teamForms, personalForms, mpaForms)"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <info-box :showPraxisInfo="true"  />
      </div>
    </div>

    <div class="d--bg-gray-100 row-lg">
      <card-header title="" v-if="enableForms === true">
        <div
          class="col-lg-4 col-md-4 col-sm-12 h7"
          v-if="personalForms.length > 0"
        >
          <b>{{ $t("emailForm.persHeader") }}</b>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 h7" v-if="mpaForms.length > 0">
          <b>{{ $t("emailForm.mpaHeader") }}</b>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 h7" v-if="teamForms.length > 0">
          <b>{{ $t("emailForm.teamHeader") }}</b>
        </div>
      </card-header>
    </div>
    <card-body style="padding-left: 0px">
      <div class="row">
        <div
          class="col-lg-4 col-md-4 col-sm-12"
          v-if="personalForms.length > 0"
        >
          <input-form-group-list
            :forms="identityStore.personalForms"
            :placeholder="emailStore.placeholder"
            formName="personalForms"
          />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" v-if="mpaForms.length > 0">
          <input-form-group-list
            :forms="identityStore.mpaForms"
            :placeholder="emailStore.placeholder"
            formName="mpaForms"
          />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" v-if="teamForms.length > 0">
          <input-form-group-list
            :forms="identityStore.teamForms"
            :placeholder="emailStore.placeholder"
            formName="teamForms"
          />
        </div>
      </div>
    </card-body>
    <info-box
      id="mailInfo"
      class="col-lg-12"
      :infoBodyText1="$t('emailForm.infoTextMail' )"
      :showEmailInfo="true"
      :showTeamIdText="emailStore.hasOnlyTeamID"
    ></info-box>
    <hr class="d--divider-h" />
    <button-box
      :routeBack="routeIdentity"
      :routeForward="routeOffer"
      :forwardBtnText="$t('emailForm.forward')"
    />
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import Autocomplete  from "@/components/form/Autocomplete.vue";
import ButtonBox from "../components/ButtonBox.vue";
import CardHeader from "../components/CardHeader.vue";
import CardBody from "../components/CardBody.vue";
import InputFormGroupList from "../components/InputFormGroupList.vue";
import InfoBox from "../components/InfoBox.vue";
import { useEmailStore }       from "@/store/EmailStore";
import { useAppStore }         from "@/store/AppStore";
import { useIdentityStore }    from "@/store/IdentityStore";
import { useInputOptionStore } from "@/store/InputOptionStore";
import { useMembersStore }     from "@/store/MembersStore";

const membersStore     = useMembersStore();
const inputOptionStore = useInputOptionStore();
const identityStore    = useIdentityStore();
const appStore         = useAppStore();
const emailStore       = useEmailStore();

appStore.activeStep = appStore.screenNumbers.email;

// Note, to assign state properties to local variables and
// retain reactivity, we must use "storeToTefs" (or use "computed").
const { praxisSoftwareOptions } = storeToRefs(emailStore);
const error = ref("");

// Forms
const teamForms = identityStore.teamForms;
const personalForms = identityStore.personalForms;
const mpaForms = identityStore.mpaForms;

const routeIdentity = appStore.routeIdentity;
const routeOffer = appStore.routeOffer;
const enableForms = emailStore.enableForms(teamForms, personalForms, mpaForms);

onMounted(() => {
  emailStore.changePlaceholder(membersStore?.associationSelection, teamForms, personalForms, mpaForms)
  if (personalForms.length === 0 && mpaForms.length === 0 && teamForms.length > 0) {
    emailStore.hasOnlyTeamID = true;
  } else {
    emailStore.hasOnlyTeamID = false;
  }
  nextTick(() => {
    // Scroll to start of page
    window.scrollTo(0, 0);
    // Select the first input element on the page
    const firstInput = document.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  });

});

watch(() => inputOptionStore.inputOptions?.associations,
  (n, o) => {
    emailStore.changePlaceholder(membersStore?.associationSelection, teamForms, personalForms, mpaForms)
  }
);
</script>

<style scoped>
#mailInfo {
  margin-left: 10px;
}
</style>
