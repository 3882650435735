<template>
  <div v-if="videoIdentStore.identSuccess.loading">
    {{ $t("identSuccess.loading.text") }}
  </div>
  <div v-else-if="videoIdentStore.identSuccess.numEids > 0">
    {{
      $t("identSuccess.withEids.intro", videoIdentStore.identSuccess.numEids)
    }}
    <FormSpacer />
    <b>{{
      $t(
        "identSuccess.withEids.tableExplanation",
        videoIdentStore.identSuccess.numEids
      )
    }}</b>
    <FormSpacer />
    <table
      class="table table-sm"
      style="width: 100%"
      :aria-label="$t('identSuccess.table.description')"
    >
      <thead>
        <tr style="border-bottom: 1pt solid grey">
          <th scope="col">{{ $t("identSuccess.table.header.name") }}</th>
          <th scope="col">{{ $t("identSuccess.table.header.email") }}</th>
          <th scope="col">{{ $t("identSuccess.table.header.subject") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(eId, index) in videoIdentStore.identSuccess.furtherEids"
          style="border-bottom: 1pt solid grey"
          :key="index"
        >
          <td>{{ eId.firstName }} {{ eId.lastName }}</td>
          <td>{{ eId.mailto }}</td>
          <td>{{ $t("identSuccess.table.body.subject") }}</td>
        </tr>
      </tbody>
    </table>

    <FormSpacer />
    {{
      $t(
        "identSuccess.withEids.infoByEmail",
        videoIdentStore.identSuccess.numEids,
        { email: videoIdentStore.identSuccess.mailto }
      )
    }}
    <FormSpacer />
    <button
      class="btn btn-primary"
      @click="sendEmails"
      :disabled="videoIdentStore.identSuccess.buttonDisabled"
    >
      <div
        v-if="videoIdentStore.identSuccess.emailSendProgress === 'not started'"
      >
        {{
          $t(
            "identSuccess.button.sendInvitationMails",
            videoIdentStore.identSuccess.numEids
          )
        }}
      </div>
      <div
        v-else-if="videoIdentStore.identSuccess.emailSendProgress === 'success'"
      >
        {{
          $t(
            "identSuccess.button.invitationMailsSent",
            videoIdentStore.identSuccess.numEids
          )
        }}
      </div>
      <div v-else>
        <!-- {{
          $t(
            "identSuccess.button.invitationMailsFailure",
            videoIdentStore.identSuccess.numEids
          )
        }} -->
        <i18n-t keypath="identSuccess.button.invitationMailsFailure" :plural="videoIdentStore.identSuccess.numEids" tag="p" scope="global">
          <template v-slot:supportLink>
            <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('identSuccess.error.supportLink')">{{ $t("identSuccess.error.support") }}</a>
          </template>
        </i18n-t>
      </div>
    </button>
  </div>
  <div v-else-if="videoIdentStore.identSuccess.errors.length">
    <p>
      {{ $t("identSuccess.error.noMatch") }}
    </p>
    <div class="h6">
      {{ $t("identSuccess.button.close") }}
    </div>
  </div>
  <div v-else>
    <p>{{ $t("identSuccess.withoutEids.text") }}</p>
      <div class="h6">
        {{ $t("identSuccess.button.close") }}
      </div>
  </div>
</template>

<script setup>
import { useAppStore } from "@/store/AppStore";
import FormSpacer from "@/components/form/FormSpacer.vue";
const appStore = useAppStore();

import { useVideoIdentStore } from "@/store/VideoIdentStore";
const videoIdentStore = useVideoIdentStore();
videoIdentStore.handleIdentSuccess();
appStore.header = "identSuccess.loading.title";

appStore.activeStep = 8;

const sendEmails = () => {
  videoIdentStore.sendEmails();
};
</script>
