<script setup>
import Autocomplete  from "@/components/form/Autocomplete.vue";
import FormTitle     from "@/components/form/FormTitle.vue";
import InputCity     from "@/components/form/InputCity.vue";
import InputCheckbox from "@/components/form/InputCheckbox.vue";
import InputEmail    from "@/components/form/InputEmail.vue";
import InputStreet   from "@/components/form/InputStreet.vue";
import InputTel      from "@/components/form/InputTel.vue";
import InputText     from "@/components/form/InputText.vue";
import InputTextName from "@/components/form/InputTextName.vue";
import DatePicker    from "@/components/form/DatePicker.vue";

const props = defineProps({
  errors: Object,
  inputs: Object,
  inputOptions: Object,
  language: "",
  validators: Object,
  hasPersEids: {type: Boolean},
});

async function handleEmailOk(event) {
  props.inputs.persEmailOk =
    await props.validators.backend(event, 'contractDetailPers');
}
</script>

<template>
  <div data-test="for-personal">
    <Autocomplete
      name="inputPersSalutation"
      :label="$t('form.salutation')"
      :language
      required="required"
      :suggestions="inputOptions.salutations"
      v-model="inputs.inputPersSalutation"
      :error="errors.inputPersSalutation"
      @validate="(event) => validators.autocomplete(event, inputOptions.salutations)"
    />
    <InputTextName
      nameFirst="inputPersFirstname"
      nameLast="inputPersLastname"
      :labelFirst="$t('form.firstName')"
      :labelLast="$t('form.lastName')"
      requiredFirst="required"
      requiredLast="required"
      v-model:valueFirst="inputs.inputPersFirstname"
      v-model:valueLast="inputs.inputPersLastname"
      :errorFirst="errors.inputPersFirstname"
      :errorLast="errors.inputPersLastname"
      @validateFirst="(event) => validators.required(event)"
      @validateLast="(event) => validators.required(event)"
    />
    <InputStreet
      nameStreet="inputPersStreet"
      nameStreetNumber="inputPersStreetNumber"
      :labelStreet="$t('form.street')"
      :labelStreetNumber="$t('form.streetNo')"
      required="required"
      v-model:valueStreet="inputs.inputPersStreet"
      v-model:valueStreetNumber="inputs.inputPersStreetNumber"
      :errorStreet="errors.inputPersStreet"
      :errorStreetNumber="errors.inputPersStreetNumber"
      @validateStreet="(event) => validators.required(event)"
      @validateStreetNumber="(event) => validators.required(event)"
    />
    <InputText
      name="inputPersAddressSuffix"
      :label="$t('contractDetail.addressAdditional')"
      v-model="inputs.inputPersAddressSuffix"
      :error="errors.inputPersAddressSuffix"
    />
    <InputCity
      nameCity="inputPersCity"
      nameZipCode="inputPersZipCode"
      :labelCity="$t('form.city')"
      :labelZipCode="$t('form.zipCode')"
      required="required"
      v-model:valueCity="inputs.inputPersCity"
      v-model:valueZipCode="inputs.inputPersZipCode"
      :errorCity="errors.inputPersCity"
      :errorZipCode="errors.inputPersZipCode"
      @validateCity="(event) => validators.required(event)"
      @validateZipCode="(event) => validators.required(event)"
    />
    <Autocomplete
      name="inputPersCountry"
      :label="$t('form.country')"
      :language
      required="required"
      :suggestions="inputOptions.countries"
      v-model="inputs.inputPersCountry"
      :error="errors.inputPersCountry"
      @validate="(event) => validators.autocomplete(event, inputOptions.countries)"
    />
    <Autocomplete
      name="inputPersLanguage"
      :label="$t('form.correspondenceLanguage')"
      :language
      required="required"
      :suggestions="inputOptions.languages"
      v-model="inputs.inputPersLanguage"
      :error="errors.inputPersLanguage"
      @validate="(event) => validators.autocomplete(event, inputOptions.orgType)"
    />
    <InputEmail
      name="inputPersEmail"
      :label="$t('contractDetail.inputs.emailCorrespondence')"
      required="required"
      v-model="inputs.inputPersEmail"
      :error="errors.inputPersEmail"
      @validate="(event) => handleEmailOk(event)"
    />
    <InputTel
      namePhoneCountry="inputPersPhoneCountry"
      namePhone="inputPersPhone"
      :label="$t('form.mobilePhone')"
      :language
      :optionsCountry="inputOptions.telCountries"
      topic="countries"
      required="required"
      v-model:selectedPhoneCountry="inputs.inputPersPhoneCountry"
      v-model:valuePhone="inputs.inputPersPhone"
      :errorPhoneCountry="errors.inputPersPhoneCountry"
      :errorPhone="errors.inputPersPhone"
      @validatePhoneCountry="(event, options) => validators.autocomplete(
        event, inputOptions.countries)"
      @validatePhone="(event) => validators.phone(event, inputs.inputPersPhoneCountry)"
    />
    <DatePicker v-show="! hasPersEids"
      name="inputPersBirthdate"
      :label="$t('form.birthdate')"
      required="required"
      v-model="inputs.inputPersBirthdate"
      :error="errors.inputPersBirthdate"
      :language="language"
      @validate="(event) => validators.required(event)"
    />

    <FormTitle
      :title ="$t('form.billingAddress')"
    />
    <InputCheckbox
      v-model="inputs.cbDifferentInvoiceAddress"
      name="cbInvoiceAddressForPersonal"
      :label="$t('form.differentInvoiceAddress')"
    />
    <div v-show="inputs.cbDifferentInvoiceAddress">
      <InputText
        name="forPers.invoiceAddress.inputLastname"
        :label="$t('form.name')"
        required="required"
        v-model="inputs.invoiceAddress.inputLastname"
        :error="errors.invoiceAddress.inputLastname"
        @validate="(event) => validators.required(event)"
      />
      <InputStreet
        nameStreet="forPers.invoiceAddress.inputStreet"
        nameStreetNumber="forPers.invoiceAddress.inputStreetNumber"
        :labelStreet="$t('form.street')"
        :labelStreetNumber="$t('form.streetNo')"
        required="required"
        v-model:valueStreet="inputs.invoiceAddress.inputStreet"
        v-model:valueStreetNumber="inputs.invoiceAddress.inputStreetNumber"
        :errorStreet="errors.invoiceAddress.inputStreet"
        :errorStreetNumber="errors.invoiceAddress.inputStreetNumber"
        @validateStreet="(event) => validators.required(event)"
        @validateStreetNumber="(event) => validators.required(event)"
      />
      <InputText
        name="forPers.invoiceAddress.inputSuffix"
        :label="$t('contractDetail.addressAdditional')"
        v-model="inputs.invoiceAddress.inputSuffix"
      />
      <InputCity
        nameCity="forPers.invoiceAddress.inputCity"
        nameZipCode="forPers.invoiceAddress.inputZipCode"
        :labelCity="$t('form.city')"
        :labelZipCode="$t('form.zipCode')"
        required="required"
        v-model:valueCity="inputs.invoiceAddress.inputCity"
        v-model:valueZipCode="inputs.invoiceAddress.inputZipCode"
        :errorCity="errors.invoiceAddress.inputCity"
        :errorZipCode="errors.invoiceAddress.inputZipCode"
        @validateCity="(event) => validators.required(event)"
        @validateZipCode="(event) => validators.required(event)"
      />
      <Autocomplete
        name="forPers.invoiceAddress.inputCountry"
        :label="$t('form.country')"
        :language
        required="required"
        :suggestions="inputOptions.countries"
        v-model="inputs.invoiceAddress.inputCountry"
        :error="errors.invoiceAddress.inputCountry"
        @validate="(event) => validators.autocomplete(event, inputOptions.countries)"
      />
    </div>

    <div v-show="!inputs.showDifferentInvoiceAddress">
      <p v-if="inputs.inputPersSalutation && inputOptions?.salutations?.byKey">
        {{   inputOptions.salutations.byKey[inputs.inputPersSalutation][language]
          || inputOptions.salutations.byKey[inputs.inputPersSalutation].de }}
      </p>
      <p>
        {{ inputs.inputPersFirstname }} {{ inputs.inputPersLastname }}
      </p>
      <p>
        {{ inputs.inputPersStreet }} {{ inputs.inputPersStreetNumber }}
      </p>
      <p>{{ inputs.inputPersAddressSuffix }}</p>
      <p>
        {{ inputs.inputPersZipCode }} {{ inputs.inputPersCity }}
      </p>
      <p>{{ inputs.inputPersCountry ? $t("countries."+inputs.inputPersCountry) : "" }}</p>
    </div>
  </div>
</template>

