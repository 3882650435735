export default {
  pageTitle: "Ordine d`adesione",
  progressSteps: {
    version: "Versione:",
    versionInfoText:
    " Health Info Net SA - ",
  },
  supportPanel: {
    remoteForm: {
      remoteMaintenance: "Teleassistenza",
      sessionNumberLabel: "Si prega di inserire il proprio numero di sessione",
      downloadBtn: "Scarica",
    },
    customerService: {
      header: "Centro assistenza clienti HIN",
      infoMail: " info{'@'}hin.ch",
      supportMail: " support{'@'}hin.ch",
      contactForm: "Modulo di contatto",
      contactFormUrl: "https://www.hin.ch/it/contatto/modulo/",
      phoneSwiss: " 0848 830 742",
      phoneInternational: " +41 52 235 02 70",
      international: " (Internazionale)",
      adminRequest: " (Richieste amministrative)",
      technicalRequest: " (Richieste tecniche)",
      openingHoursTitle: "Orari di apertura: ",
      openingHours: "Lunedì – venerdì ",
      openingHoursNoon: "Ore 9:00 – 11:00",
      openingHoursAfternoon: "Ore 13:30 – 15:30",
    },
    globalSupport: {
      header: "Assistenza HIN Mail GLOBAL",
      receiverMail: "Per destinatari di e-mail crittografate",
      globalSupportMail: " global.support{'@'}hin.ch",
      globalSupportPhone: " 0848 22 44 11",
      globalSupportPhoneInternational: " +41 52 235 02 71",
    },
    adress: {
      header: "Indirizzo",
      companyName: "Health Info Net AG",
      street: "Seidenstrasse 4",
      zipCity: "8304 Wallisellen",
    },
  },
  appErrors: {
    backendCommunicationError:
      "Si è verificato un problema di connessione al server." +
      " Si prega di riprovare più tardi." +
      " Se il problema dovesse persistere si prega di rivolgersi all’{supportLink}.",
      failedBackendCall:
      "Non è possibile procedere alla validazione. Si prega di verificare la connessione Internet.",
  },
  appComponents: {
    appHeaderTitle: "Sottoscrivere un’adesione a HIN",
    inputTeamName: "Nome visualizzato",
    btnBack: "Indietro",
    smallText:
      "Questo nome viene visualizzato come mittente dell’e-mail, ad esempio studio medico Esempio.",
    inputFirstName: "il nome",
    inputLastName: "il cognome",
    inputEmail: "l'indirizzo e-mail (esistente)",
    cacheDataText: "Salvare lo stato attuale e continuare in un secondo momento",
  },
  membersForm: {
    memBodyText:
      "L'ordine di un'adesione a HIN prevede un’{memTitle} da effettuare personalmente. Per l’ordine (compresa identificazione video) sono necessari ca. 30 minuti.",
    memTitle: "identificazione video",
    memExplainText:
      "Video esplicativo «Come funziona l’identificazione video?» (attualmente disponibile solo in tedesco e in francese)",
    memExplainLink: "https://support.hin.ch/it/identificazione-video/procedura-di-identificazione-video/",
    memPricingLink: "https://www.hin.ch/it/adesione/adesione-a-hin.cfm",
    memFooterTitle: "Selezionare l’associazione",
    firstInfoBoxText: "Per le adesioni",
    secondInfoBoxText:
      "valgono regole specifiche. Per procedere all’ordine si prega di utilizzare il seguente link:",
    orderLink: "Panoramica dei prodotti",
    memListText: {
      header: "Sono necessari:",
      internetAccess: "Accesso a Internet – buona connessione durante l’intero processo",
      mobilePhone: "Cellulare o tablet/computer con webcam e microfono (browser: Google Chrome, Mozilla Firefox e Microsoft Edge)",
      validId: "Documento d’identità valido ",
      mobilePhoneAccess: "Accesso a un telefono cellulare",
      glnNumber: "Il proprio GLN (se si richiede l’utilizzo per la CIP)",
      validDocs: "Documenti d’identità ammessi:",
      idSwiss: "Persone con cittadinanza svizzera: carta d’identità svizzera o passaporto svizzero",
      idForeign: "Persone senza cittadinanza svizzera: documento d’identità valido del Paese di provenienza con carta di soggiorno",
    },
    memAssociation: {
      header: "Offerte speciali",
      overviewLink: "panoramica sulle adesioni",
      specialCondition:
        "I membri di un’associazione possono beneficiare di condizioni speciali (si veda la {overviewLink}), " +
        "ad esempio per quanto riguarda la quota annuale e il dominio dell’indirizzo e-mail {'(esempio: @associazione-professionale-hin.ch)'}.",
    },
    modalTitle: "NOTA BENE",
    modalMemberTitle: "Adesione a HIN {association}",
    // removed {association} variable here due to text changes
    // code for using the variable is still available in case it is needed
    modalText:
      "A questa offerta si applicano condizioni speciali. " +
      "Si prega di procedere all’ordine tramite il seguente link: ",
    modalTest:
      "A questa offerta si applicano condizioni speciali. "
      +
      "Si prega di procedere all’ordine tramite il seguente link: ",
    modalTextUrl: "Ordinazione offerta",
    modalDismissButton: "OK",
    selectionNoAssociation: "Nessuna associazione partner",
  },
  identityForm: {
    personalCard: {
      persCardHeader: "La mia eID personale",
      compareToId:
        "È comparabile a un documento d’identità ma in formato digitale",
      uniquePerson:
        "Soltanto una persona è autorizzata a utilizzare l’eID personale",
      appAccess:
        "Accesso ad applicazioni con dati sensibili (ad es. CIP, servizio E-Ricetta Svizzera)",
      hinServices:
        "Utilizzo dei servizi HIN come HIN Mail e HIN Sign",
      emailExample: "L’indirizzo e-mail associato a un’eID personale è, ad esempio, mario.rossi{'@'}hin.ch",
    },
    teamCard: {
      teamCardHeader: "eID di team",
      multiplePersons: "Può essere utilizzata da più persone congiuntamente",
      limitedAccessData: "Nessun accesso a dati degni di particolare protezione (ad es. CIP, servizio E-Ricetta Svizzera)",
      limitedAccessServices: "Accesso limitato ai servizi HIN",
      emailExample: "L’indirizzo e-mail associato a un’eID di team è, ad esempio, studio.rossi{'@'}hin.ch"
    },
    addButtons: {
      teamLinkText: "Aggiungere un’eID di team",
      persLinkText: "Aggiungere un’eID personale",
      mpaLinkText: "Aggiungere un’eID per ASM / CMA / personale farmaceutico specializzato",
    },
    firstPersonalForm: "eID personale (contraente/titolare dell’eID)",
    formString: "",
    persFormName: "eID personale (titolare dell’ID)",
    mpaFormString: "eID personale per ASM (titolare dell’ID)",
    trashCanText: "Rimuovi",
    whatIsEid: "Cos’è un’eID?",
    whatIsEidLink: "https://support.hin.ch/it/identita-hin/differenza-identita-personale-identita-dello-studio-medico/",
    errors: {
      duplicateMailError: "Un indirizzo e-mail non può essere utilizzato per diverse eID. " +
        "L’indirizzo inserito deve essere personale poiché viene utilizzato per generare la password.",
      hinMailExistingError: "L’indirizzo e-mail {currentHinEmail} è già assegnato a un’eID HIN. " +
      "Si prega di contattare l’{supportLink} per chiarire come procedere.",
    },
  },
  emailForm: {
    forward: "Avanti",
    persHeader: "eID personale",
    mpaHeader: "eID personale per ASM / CMA / farmacista",
    teamHeader: "eID di team",
    infoTextMail:
      "Gli indirizzi e-mail elencati sopra sono solo proposte. Gli indirizzi e-mail definitivi saranno verificati e assegnati in seguito. ",
    infoTextPleaseConsider: "Per la scelta dell’indirizzo e-mail desiderato per l'eID personale si prega di considerare i seguenti aspetti:",
    infoTextFullName: "L’indirizzo e-mail deve contenere obbligatoriamente il proprio cognome completo",
    infoTextFirstName: "È possibile abbreviare il proprio nome o indicarlo per intero",
    infoTextFantasyName: "Non sono ammessi nomi di fantasia",
    praxisSoftwareLabel:
      "Combinare l’adesione con i seguenti software per studio medico",
    praxisSoftwareInfo:
      "La combinazione con il software per studio medico influisce sul dominio dell’e-mail.",
    praxisSoftwareSelect: "Selezionare il software per studio medico",
    noPraxisSoftware: "No software",
    praxisSoftwareOptions: {
      "No software": "Non collegare alcun software per studio medico",
      softplus: "Softplus",
      physiosys: "Physiosys",
      sophisware: "Sophisware",
    },
  },
  offerForm: {
    errorModal: {
      header: "Si è verificato un errore",
      text: "Purtroppo non siamo riusciti a creare l’offerta. Si prega di rivolgersi all’{supportLink}.",
      errorCode: "Codice di errore",
    },
    offerMismatchModal: {
      header: "NOTA BENE:",
      intro: "Il numero minimo/massimo di eID personali e/o il numero minimo/massimo di "
        +"Il numero di eID di team – o eventualmente solo il numero di eID per questa offerta associativa – non è soddisfatto. "
        +"Si prega di ordinare la quantità necessaria, altrimenti lo sconto speciale non sarà applicato. "
        +"Inoltre non sarà possibile ottenere un indirizzo e-mail con l’indicazione dell’associazione. "
        +"Le condizioni sono disponibili al link www.hin.???",
      numTeamIds: "eID di team",
      numTotPersIds: "Numero di eID personali (incl. ASM)",
      numTotIds: "Numero complessivo di eID personali e di eID di team",
      minimum: "Minimo",
      maximum: "Massimo",
    },
    pageHeaders: {
      single: "Adesione individuale",
      collective: "Adesione collettiva",
      collectiveAssociation: "Adesione collettiva associazione",
    },
    tableHeaders: {
      countText: "Numero",
      singlePriceText: "Prezzo singolo",
      totalPriceText: "Prezzo totale",
    },
    tableContentAdmission: {
      admissionCostText: "Costi una tantum",
      admissionChargeText: "Tassa di ammissione una tantum",
      associationBenefitText: "Vantaggio dell’offerta associativa",
      admissionCostTotalText: "Totale costi una tantum",
      admissionFeeText: "Tassa di ammissione",
      admissionDiscountText: "Vantaggio dell’offerta associativa per l’associazione { name }",
    },
    tableContentAnnual: {
      annualCostText: "Costi annuali",
      personalIdsText: "Quota annuale eID personale",
      personalIdsDiscount: "Sconto",
      mpaIdsText: "Quota annuale eID personale per ASM",
      teamIdsText: "Quota annuale eID di team",
      annualDiscountText: "Vantaggio dell’offerta associativa per l’associazione { name }",
      annualCostTotalText: "Totale costi annuali",
    },
    sponsorText: "Sponsor:",
    totalAmountText: "Importo totale",
    btnForward: "Accetta e continua",
    followUp: "Seguono dettagli contrattuali",
    retired: "Sono una pensionata / un pensionato",
    currency: "CHF",
    mutationFeeText: "Quota di mutazione",
  },
  contractDetail: {
    button: {
      back: "Indietro",
      forward: "Sottoscrivi contratto",
      savingInProgressTitle: "Salvataggio in corso",
      savingInProgressText: "Si prega di attendere",
      eIdErrorsTitle: "Dati errati nel passo 3",
      eIdErrorsText:
        "Gli indirizzi e-mail indicati non soddisfano i requisiti."
        + " Si prega di verificare i propri dati nel passo 3.",
      formErrorsTitle: "Dati mancanti",
      formErrorsText: "Si prega di compilare tutti i campi contrassegnati con asterisco (*).",
      savingErrorsTitle: "Errore durante il salvataggio",
      savingErrorsText:
        "Si è verificato un errore durante il salvataggio. Si prega di verificare la propria connessione Internet."
        + " Se il problema dovesse persistere si prega di rivolgersi all’{supportLink}.",
      savingTitle: "Dati OK",
      savingText: "Salvataggio dati in corso Reindirizzamento",
    },
    title: "Contratto",
    noBelongToOrg: "Non appartengo a nessuna organizzazione/istituzione",
    forOrganization: {
      title: "Organizzazione",
      titleBusinessContactPerson: "Interlocutrice/interlocutore",
      titleHint: "(Contraente)",
      orgName: "Nome dell’organizzazione",
      email: "Indirizzo e-mail dell’organizzazione (esistente)",
      tel: "Telefono dell’organizzazione",
      gln: "GLN dell’organizzazione",
      glnLookupPending: "Si prega di attendere: stiamo cercando il GLN in Refdata",
      businessContactPhone: "Telefono cellulare",

      cbAckLegalOrgSingleMembership:
        "Con la presente confermo" +
        " che i requisiti di base per la stipula del contratto sono soddisfatti" +
        " e che i dati forniti sono veritieri." +
        " Ho inoltre letto e accettato i seguenti documenti contrattuali: {cbAckLegalOrgSingleMembershipContract}",
      cbAckLegalOrgSingleMembershipContract: "Contratto base adesione individuale",
      cbAckLegalOrgSingleMembershipContractLink: "https://www.hin.ch/files/pdf1/contratto_quadro_adesione_individuale_a_hin.pdf",
      cbAckLegalOrgSinglePersonallyResponsible:
        "Ho preso atto" +
        " che, con la stipula del contratto in qualità di membro individuale HIN," +
        " sono personalmente responsabile ai sensi della legge sulla protezione dei dati – e" +
        " non l’organizzazione indicata.",

      cbAckLegalOrgCollectiveMembership:
        "Con la presente confermo" +
        " che i requisiti di base per la stipula del" +
        " contratto sono soddisfatti e che i dati forniti" +
        " sono veritieri." +
        " Ho inoltre letto e accettato i seguenti documenti contrattuali: {cbAckLegalOrgCollectiveMembershipContract}",
        cbAckLegalOrgCollectiveMembershipContract: "Contratto base adesione collettiva",
        cbAckLegalOrgCollectiveMembershipContractLink: "https://www.hin.ch/files/pdf1/contratto_quadro_adesione_collettiva_a_hin.pdf",
    },
    forPersonal: {
      cbAckLegalPersSingleMembership:
        "Con la presente confermo" +
        " che i requisiti di base per la stipula del" +
        " contratto sono soddisfatti e che i dati forniti" +
        " sono veritieri." +
        " Ho inoltre letto e accettato i seguenti documenti contrattuali: {cbAckLegalPersSingleMembershipContract}",
        cbAckLegalPersSingleMembershipContract: "Contratto base adesione individuale",
        cbAckLegalPersSingleMembershipContractLink: "https://www.hin.ch/files/pdf1/contratto_quadro_adesione_individuale_a_hin.pdf",
      cbAckLegalPersSinglePersonallyResponsible:
        "Ho preso atto" +
        " che, con la stipula del contratto" +
        " in qualità di membro individuale HIN, sono personalmente responsabile ai sensi della legge sulla protezione dei dati.",

      cbAckLegalPersCollectiveMembership:
        "Con la presente confermo" +
        " che i requisiti di base per la stipula del" +
        " contratto sono soddisfatti e che i dati forniti" +
        " sono veritieri." +
        " Ho inoltre letto e accettato i seguenti documenti contrattuali: {cbAckLegalPersCollectiveMembershipContract}",
        cbAckLegalPersCollectiveMembershipContract: "Contratto base adesione collettiva",
        cbAckLegalPersCollectiveMembershipContractLink: "https://www.hin.ch/files/pdf1/contratto_quadro_adesione_collettiva_a_hin.pdf",
      cbAckLegalPersCollectivePersonallyResponsible:
        "Ho preso atto" +
        " che, senza l’indicazione di un’organizzazione" +
        " (persona giuridica), sono personalmente responsabile del contratto stipulato" +
        " ai sensi della legge sulla protezione dei dati.",
    },
    inputs: {
      emailCorrespondence: "Indirizzo e-mail per la corrispondenza (esistente)",
      orderCovercardService: "Desidero attivare il {covercardService}",
      covercardService: "Servizio Covercard",
      covercardServiceLink: "https://www.hin.ch/it/servizi/hin-servizio-covercard/servizio-covercard-hin.cfm",
      covercardTitle: "Covercard",
      covercardItemPrice: "CHF { amount }",
      cardReaderInfoText: "{cardReaderHere} è possibile trovare un elenco dei lettori di carte supportati dal proprio software di studio medico.",
      cardReaderlink: "https://download.hin.ch/files/HIN_Praxissoftwarehersteller_Kartenleser_de.pdf",
      cardReaderHere: "Qui"
    },
    addressAdditional: "Indirizzo aggiuntivo",
    organizationType: "Tipo di organizzazione",
  },
  furtherInformation: {
    glnInfoUrl: "https://www.refdata.ch/de/partner/abfrage/partner-refdatabase-gln",
    button: {
      back: {
        warningTitle: "Non è possibile tornare al contratto",
        warningText:
          "I dati del contratto non possono più essere modificati. Si prega di completare il procedura di ordinazione " +
          "(compresa l’identificazione video) e di comunicarci eventuali modifiche telefonicamente al numero {backPhoneNumber} " +
          "oppure tramite {backContactForm}.",
        backPhoneNumber: "0848 830 740",
        backPhoneNumberLink: "tel:0848%20830%20740",
        backContactForm: "modulo di contatto",
      },
      formErrorsTitle: "Dati mancanti",
      formErrorsText: "Si prega di compilare tutti i campi contrassegnati con asterisco (*).",
      savingErrorsTitle: "Errore durante il salvataggio",
      savingErrorsText:
        "Si è verificato un errore durante il salvataggio. Si prega di verificare la propria connessione Internet."
        + " Se il problema dovesse persistere si prega di rivolgersi all’{supportLink} ",
      savingInProgressTitle: "Salvataggio in corso",
      savingInProgressText: "Si prega di attendere",
      savingTitle: "Dati OK",
      savingText: "Salvataggio dati in corso Reindirizzamento",
    },
    glnLookupPending:
      "Si prega di attendere. Stiamo cercando il GLN nei registri",
    person: {
      // removed (Maximum: 3) from the key so the text fits in the button
      addJobName: "Aggiungere un’ulteriore professione sanitaria",
      title: "Dati personali",
      header: "eID personale per",
      gln: "GLN persona",
      accessGln: "Per accedere alla CIP è obbligatoriamente necessario specificare un GLN.",
      mpaEpdGlnHint:
        "Per accedere alla CIP è vincolante indicare un GLN." +
        " È possibile concludere la procedura di ordinazione senza l’indicazione di un GLN." +
        " Nel caso in cui sia necessario accedere alla CIP in un secondo momento," +
        " il GLN può essere indicato successivamente.",
      ackLegal:
        "Con la presente confermo che i dati forniti sono veritieri" +
        " e accetto la {privacyPolicy}.",
      privacyPolicy: "dichiarazione sulla protezione dei dati HIN",
      privacyPolicyLink: "https://www.hin.ch/it/dichiarazione-sulla-protezione-dei-dati/",
      button: {
        forward: "Avanti",
      },
    },
    infoText:
      "Per accedere alla CIP serve obbligatoriamente un GLN. Si prega di indicare il proprio GLN " +
      "se è disponibile. ",
    infoTextFindGln: "Ricerca GLN",
    cardReaderOrder: "Lettore a pagamento",
    practiceSoftware: "Quale software per studio medico utilizza?",
    tokenExpiredTitle: "Termine di registrazione scaduto",
    tokenExpiredText:
      "La propria finestra temporale per la registrazione è scaduta. Si prega di contattare l’{supportLink}.",
  },
  videoIdentification: {
    loading: "Si prega di attendere",
    errorTitle: "Si è verificato un errore durante il caricamento dell’identificazione video",
    errorText:
      "Si prega di riprovare. Se il problema dovesse persistere" +
      " si prega di rivolgersi all’{supportLink}.",
    title: "identificazione video",
    introText:
      "Durante l’{title} vengono verificati i propri dati" +
      "sulla base di un documento d’identità valido.",
    introLink: "Maggiori dettagli sul processo di identificazione video",
    availability: "Reperibilità",
    businessHours: "Da lunedì a sabato dalle ore 07:00 alle 22:00",
    listHeader:
      "L’identificazione video richiede ca. 10 minuti. Sono necessari:",
    button: {
      back: "Indietro",
      forward: "Avvia identificazione",
    },
    list: {
      computerOrSmartphone: "Computer o smartphone con microfono e fotocamera",
      validIdentityDocument: "Documento d’identità valido",
      quietBrightPlace: "Un luogo tranquillo e luminoso",
    },
    documentInfo: {
      ifUsingSmartphone:
        "Se si utilizza uno smartphone:" +
        " bisogna assicurarsi di essere connessi solo alla rete 4G e che non sia attiva alcuna connessione Wi-Fi.",
      alsoMakeSure:
      "Si prega inoltre di assicurarsi che il proprio telefono sia aggiornato.",
      withSwissCitizenship:
        "Persone con cittadinanza svizzera:" +
        " carta d’identità svizzera o passaporto svizzero",
      withoutSwissCitizenship:
        "Persone senza cittadinanza svizzera:" +
        " documento d’identità valido del Paese d’origine con carta di soggiorno",
    },
  },
  identSuccess: {
    withEids: {
      title:
        "Invito per identità personale | Inviti per identità personali",
      intro:
        "È stato effettuato un ordine di un’identità personale (eID) per conto di un’altra persona." +
        " Si prega di informare questa persona sui passi successivi" +
        " e di inviarle un invito per l’identificazione video." +
        " | È stato effettuato un ordine di più identità personali (eID) per conto di altre persone." +
        " Si prega di informare queste persone sui passi successivi" +
        " e di inviare loro un invito per l’identificazione video.",
      tableExplanation:
        "Inviare l’invito via e-mail | Inviare gli inviti via e-mail",
      infoByEmail:
      "Dopo l'elaborazione dell'eID, i dati di accesso vengono inviati tramite e-mail."
        // "Ulteriori informazioni su come procedere saranno disponibili" +
        // " una volta inviato l’invito via e-mail all’indirizzo { email }." +
        // " | Ulteriori informazioni su come procedere saranno disponibili" +
        // " una volta inviati gli inviti via e-mail agli indirizzi { email }.",
    },
    withoutEids: {
      title: "L’identificazione video è andata a buon fine",
      text:
        "L’identificazione video è andata a buon fine." +
        " Abbiamo inviato via e-mail le informazioni su come procedere.",
    },
    error: {
      title: "Si è verificato un errore durante il trasferimento dei dati",
      restart: "Si prega di riavviare l’identificazione video:",
      text:
        "È possibile effettuare l’identificazione anche in un secondo momento." +
        " Abbiamo inviato il link corrispondente" +
        " all’indirizzo {email}.",
      restartIdent: "qui",
      noMatch:
        "Non è stato possibile trovare i dati. Si prega di rivolgersi" +
        " all’{supportLink}.",
      supportLink: "https://support.hin.ch/it/",
      support: "Assistenza HIN",
    },
    loading: {
      title: "Si prega di attendere",
      text: "Specifica TODO",
    },
    table: {
      description:
        "Tabella con elenco delle eID che ricevono un’e-mail di invito",
      header: {
        name: "Titolare dell’ID",
        email: "Indirizzo dell’e-mail",
        subject: "Oggetto dell’e-mail",
      },
      body: {
        subject: "Attivazione dell’eID personale",
      },
    },
    button: {
      sendInvitationMails:
        "Invia e-mail e chiudi finestra" +
        " | Invia e-mail e chiudi finestra",
      invitationMailsSent:
        "E-mail inviata. Ora è possibile chiudere la finestra" +
        " | E-mail inviate. Ora è possibile chiudere la finestra",
      invitationMailsFailure:
        "Errore durante l’invio. Si prega di contattare l’{supportLink}.",
      close: "Ora è possibile chiudere la finestra",
    },
  },
  // Generic keys for forms
  form: {
    button: {
      forward: "Avanti",
    },
    association: "Associazione professionale",
    associationMemberNumber: "Numero membro dell’associazione professionale",
    birthdate: "Data di nascita",
    correspondenceLanguage: "Lingua di corrispondenza",
    errorMessage: "I campi contrassegnati in rosso sono vuoti o contengono dati errati.",
    street: "Via",
    streetNo: "N° civico",
    city: "Località",
    zipCode: "NPA",
    selectionPlease: "(si prega di selezionare)",
    country: "Paese",
    glnErrors: {
      onlyDigits: "Il GLN può contenere solo cifre.",
      noMatch: "Il GLN inserito non è stato trovato nel registro.",
      noMatchPerson:
        "Nessuna voce trovata. Si prega di inserire un GLN valido o di lasciare il campo vuoto.",
      tooShort: "Il GLN specificato è troppo corto.",
      tooLong: "Il GLN specificato è troppo lungo.",
      unknownError:
        "Non è stato possibile trovare il GLN specificato.",
    },
    glnText:
      "Il Global Location Number (GLN) è necessario per l’identificazione univoca" +
      " di persone fisiche e giuridiche (ad es. professionista della salute, organizzazione sanitaria)" +
      " e le ubicazioni fisiche (ad es. edificio, magazzino). {0}",
    glnTitle: "GLN",
    glnUrlText: "Ulteriori informazioni",
    profession: "Professione / professione sanitaria",
    profTitle: "Titolo specialistico",
    salutation: "Appellativo",
    salTitle: "Titolo accademico",
    mobilePhone: "Telefono cellulare",
    name: "Nome completo",
    firstName: "Nome",
    lastName: "Cognome",
    billingAddress: "Indirizzo di fatturazione",
    differentInvoiceAddress: "Indirizzo di fatturazione diverso",
    feedbackMessageTitle: "Nota",
    feedbackMessageLabel: "Si desidera fare qualche osservazione?",
    contractTerms: "Disposizioni contrattuali",
    errors: {
      "Failed string anyNv missing parameter value":
        "Si prega di effettuare una selezione.",
      "Missing property": "Si prega di compilare",
      codeLabel: "Codice di errore: ",
      specialCharacters: "Non utilizzare né caratteri speciali né numeri",
      missingProperty: "Si prega di inserire {label}",
      unknownMailServer: "Il dominio e-mail (ad es. {'@'}hin.ch) non può essere modificato.",
      invalidEmail: "Si prega di indicare un indirizzo e-mail valido.",
      checkRegulations: "Si prega di accettare le disposizioni contrattuali",
      invalidTelNrSwitzerland: "Si prega di inserire un numero di telefono di 9 cifre",
      invalidTelNrLiechtenstein: "Si prega di inserire un numero di telefono di 7 cifre",
      invalidTelNrOther: "Si prega di inserire un numero di telefono di 7-13 cifre",
    },
  },
  Fernwartung:
    "TODO: make key English, Fill in Text. I have just put the key here to silence a warning",
  countries: {
    CH: "Svizzera",
  },
  phoneCountryCodes: {
    CH: "CH +41",
  },
  register: {
    infoFoundInRegister: "Voce trovata nel {register}",
  },
  sidebar: {
    steps: {
      membership: "Note sull'ordine",
      identities: "Identità (eID)",
      identitiesAndMail: "Identità (eID) e indirizzi e-mail",
      hinEmails: "Indirizzi e-mail",
      hinEmailSelect: "Selezionare indirizzi e-mail",
      offer: "Offerta",
      contractDetail: "Dettagli contrattuali",
      furtherInfo: "Dati personali",
      videoIdentification: "Identificazione video",
    },
  },
};
