<script setup>
import { ref } from "vue";

const showPanel = ref(false);
</script>

<template>
  <div class="hreg--sidebar-nav d-inline">
    <ul>
      <li>
        <a
          id="hreg--btn-sidebar-support"
          href="#"
          @click="showPanel = !showPanel"
        >
          <svg
            aria-hidden="false"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#666" fill-rule="nonzero">
              <path
                d="M4 5c-.548 0-1 .452-1 1v12c0 .548.452 1 1 1h16c.548 0 1-.452 1-1V6c0-.548-.452-1-1-1H4zm0-2h16c1.652 0 3 1.348 3 3v12c0 1.652-1.348 3-3 3H4c-1.652 0-3-1.348-3-3V6c0-1.652 1.348-3 3-3z"
              ></path>
              <path
                d="M12 11.78l9.427-6.6a1 1 0 111.146 1.64l-10 7a1 1 0 01-1.146 0l-10-7a1 1 0 111.146-1.64L12 11.78z"
              ></path>
            </g>
          </svg>
        </a>
      </li>
    </ul>
  </div>

  <div
    id="hin--sidebar-support"
    :class="['hreg--sidebar-support', showPanel && 'open']"
  >
    <div id="hreg--sidebar-panel-contact">
      <div class="hreg--sidebar-widget">
        <!-- <h5>{{ $t("supportPanel.remoteForm.remoteMaintenance") }}</h5>
        <p>{{ $t("supportPanel.remoteForm.sessionNumberLabel") }}</p>
        <form
          id="hreg--form-remote-session"
          name="hreg--form-remote-session"
          class="hreg--form needs-validation w-100"
          autocomplete="off"
          method="post"
          action=""
          novalidate=""
        >
          <div class="form-group required">
            <input
              type="text"
              class="form-control"
              id="sessionNumberInput"
              name="sessionNumberInput"
              minlength="2"
              maxlength="50"
            />
          </div>
          <button
            class="btn btn-primary btn-block"
            type="submit"
            data-loading-text="Sending"
          >
            {{ $t("supportPanel.remoteForm.downloadBtn") }}
            <i class="mdi mdi-arrow-right"></i>
          </button>
        </form>
      </div> -->
        <h5>{{ $t("supportPanel.customerService.header") }}</h5>
        <!-- <div class="mb-2">
          <i class="mdi mdi-email-outline"></i>
          <a href="mailto:info@hin.ch">{{
            $t("supportPanel.customerService.infoMail")
          }}</a>
          <span class="text-muted">{{
            $t("supportPanel.customerService.adminRequest")
          }}</span>
        </div>
        <div class="mb-3">
          <i class="mdi mdi-email-outline"></i>
          <a href="mailto:support@hin.ch">{{
            $t("supportPanel.customerService.supportMail")
          }}</a>
          <span class="text-muted">{{
            $t("supportPanel.customerService.technicalRequest")
          }}</span>
        </div> -->
        <div class="mb-2">
          <i class="mdi mdi-phone-outline"></i>
          <a class="text-primary" href="tel:0848%20830%20740">{{
            $t("supportPanel.customerService.phoneSwiss")
          }}</a>
        </div>
        <div class="mb-3">
          <i class="mdi mdi-phone-outline"></i>
          <a class="text-primary" href="tel:+41%2052%20235%2002%2070">{{
            $t("supportPanel.customerService.phoneInternational")
          }}</a>
          <span class="text-muted">{{
            $t("supportPanel.customerService.international")
          }}</span>
        </div>
        <div class="mb-3">
          <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('supportPanel.customerService.contactFormUrl')">{{
            $t("supportPanel.customerService.contactForm")
          }}</a>
        </div>
        <p>
          {{ $t("supportPanel.customerService.openingHoursTitle") }} 
          <br>
          {{ $t("supportPanel.customerService.openingHours") }}
          <br>
          {{ $t("supportPanel.customerService.openingHoursNoon") }}
          <br>
          {{ $t("supportPanel.customerService.openingHoursAfternoon") }}
        </p>
      </div>
    </div>
    <!-- <div class="hreg--sidebar-widget border-bottom">
      <h5>{{ $t("supportPanel.globalSupport.header") }}</h5>
      <p>
        <strong>{{ $t("supportPanel.globalSupport.receiverMail") }}</strong>
      </p>
      <div class="mb-3">
        <i class="mdi mdi-email-outline"></i>
        <a href="mailto:global.support@hin.ch">{{
          $t("supportPanel.globalSupport.globalSupportMail")
        }}</a>
      </div>
      <div class="mb-2">
        <i class="mdi mdi-phone-outline"></i>
        <a href="tel:0848%2022%2044%2011">{{
          $t("supportPanel.globalSupport.globalSupportPhone")
        }}</a>
      </div>
      <div>
        <i class="mdi mdi-phone-outline"></i>
        <a href="tel:+41%2052%20235%2002%2071">{{
          $t("supportPanel.globalSupport.globalSupportPhoneInternational")
        }}</a>
        <span class="text-muted">{{
          $t("supportPanel.customerService.international")
        }}</span>
      </div>
    </div>
    <div class="hreg--sidebar-widget">
      <h5>{{ $t("supportPanel.adress.header") }}</h5>
      <p>
        {{ $t("supportPanel.adress.companyName") }}<br />
        {{ $t("supportPanel.adress.street") }}<br />
        {{ $t("supportPanel.adress.zipCity") }}
      </p>
    </div> -->
  </div>
</template>
<style scoped>
.max-sidebar-width {
  max-width: 260px;
}
</style>
