import { defineStore } from "pinia";
import { watch } from "vue";
import { useVideoIdentStore } from "./VideoIdentStore";

export const useAppStore = defineStore("AppStore", {
  state: () => {
    return {
      currentLanguage: "DE",
      activeStep: 0,
      activeStepInvite: 1,
      screenNumbers: {
        home: 1,
        identity: 2,
        email: 3,
        offer: 4,
        contractDetail: 5,
        furtherInformation: 6,
        videoIdentWelcome: 7,
        videoIdent: 8,
        videoIdentSuccess: 9,
        videoIdentFailed: 10,
      },
      isInvitedUser: false,
      header: "sidebar.steps.membership",
      // Usually 1, can be changed for translation pluralization
      headerNum: 1,
      btnForward: "Weiter",
      btnBack: "Zurück",
      // Route
      routeHome: "/#",
      routeIdentity: "/membership-order",
      routeEmail: "/membership-email",
      routeOffer: "/membership-offer",
      routeContract: "/membership-contract-detail",
      // Token we get from backend after screen 4
      token: "",
    };
  },
  actions: {
    changeHeader(router) {
      watch(router, (to) => {
        if (to.path === "/") {
          this.header = "sidebar.steps.membership";
        } else if (to.path === "/membership-order") {
          this.header = "sidebar.steps.identities";
        } else if (to.path === "/membership-email") {
          this.header = "sidebar.steps.hinEmailSelect";
        } else if (to.path === "/membership-offer") {
          this.header = "sidebar.steps.offer";
        } else if (to.path === "/membership-contract-detail") {
          this.header = "sidebar.steps.contractDetail";
        } else this.header = "sidebar.steps.membership";
      });
    },


  async handleIdentInvitation() {
    const videoIdentStore = useVideoIdentStore();
    const params = new URLSearchParams(window.location.search);
    
    // commented out this code, so we can first test the happy path 
    // first we want to see if the redirection works if we got the token as a query parameter 
    // if (params.has('invitation') && params.get('invitation') === videoIdentStore.token) {
      if (params.has('invitation')){
      videoIdentStore.token = params.get('invitation');
      this.currentLanguage = params.has('vi-lang') ? params.get('vi-lang').toUpperCase() : 'DE';
      this.isInvitedUser = true;
    } else {
      this.isInvitedUser = false;
    }
    }
    },
    persist: {
      storage: sessionStorage,
    }
});
