<template>
  <div>
    <div class="hreg--card-info">
      <div v-if="videoIdentStore.token && videoIdentStore.type === 'personal'">
        <div>
          <div class="d--bg-gray-100">
            <!-- add Organisation name for invited user when it is available -->
            <CardHeader
              :title="
                $t('furtherInformation.person.header') +
                ' ' +
                videoIdentStore.firstName +
                ' ' +
                videoIdentStore.lastName +
                (!appStore.isInvitedUser ? ' (' + videoIdentStore.hinEmail + ')' : '')
              "
            />
          </div>
          <CardBody class="d--mvt-7 padding-zero">

            <ForPers
              :displayAssociation
              :errors="errors.forPers"
              :inputs="inputs.forPers"
              :info="info.forPers"
              :inputOptions
              :professionsWithTitle="videoIdentStore.professionsWithTitle"
              :language="appStore.currentLanguage.toLowerCase()"
              :validators="validators(errors.forPers, unsetError)"
            />
          </CardBody>
        </div>
        <div class="hreg--buttonbar">
          <Popper placement="top" arrow>
            <button v-if="!appStore.isInvitedUser" type="button" class="btn btn-link">
              {{ $t("appComponents.btnBack") }}
            </button>
            <template #content>

              <h6>{{ $t("furtherInformation.button.back.warningTitle") }}</h6>
              <i18n-t keypath="furtherInformation.button.back.warningText" tag="p" scope="global">
                <template v-slot:backPhoneNumber>
                  <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" href="tel:0848%20830%20740">{{ $t("furtherInformation.button.back.backPhoneNumber") }}</a>
                </template>
                <template v-slot:backContactForm>
                  <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('supportPanel.customerService.contactFormUrl')">{{ $t("furtherInformation.button.back.backContactForm") }}</a>
                </template>
              </i18n-t>
            </template>
          </Popper>

          <Popper placement="top" arrow>
            <button
              data-test="submitFurtherInformation"
              type="submit"
              class="btn btn-primary"
              data-loading-text="Processing"
              :style="disableSubmit ? 'opacity:.45' : ''"
              @click="handleSubmit"
            >
              {{ $t("furtherInformation.person.button.forward") }}
            </button>
            <template #content>
              <div v-if="videoIdentStore.savingInProgress">
                <h6>
                  {{ $t("furtherInformation.button.savingInProgressTitle") }}
                </h6>
                <p>
                  {{ $t("furtherInformation.button.savingInProgressText") }}
                </p>
              </div>
              <div v-else-if="Object.keys(videoIdentStore.savingErrors).length > 0">
                <h6>{{ $t("furtherInformation.button.savingErrorsTitle") }}</h6>
                <i18n-t keypath="furtherInformation.button.savingErrorsText" tag="p" scope="global">
                  <template v-slot:supportLink>
                    <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('identSuccess.error.supportLink')">{{ $t("identSuccess.error.support") }}</a>
                  </template>
                </i18n-t>
                <p>
                  {{ $t("form.errors.codeLabel") }}:
                  {{videoIdentStore.savingErrors[0].code}}
                </p>

              </div>
              <div v-else-if="Object.keys(videoIdentStore.flatErrors).length > 0">
                <h6>{{ $t("furtherInformation.button.formErrorsTitle") }}</h6>
                <p>{{ $t("furtherInformation.button.formErrorsText") }}</p>
              </div>
              <div v-else>
                <h6>{{ $t("furtherInformation.button.savingTitle") }}</h6>
                <p>{{ $t("furtherInformation.button.savingText") }}</p>
              </div>
            </template>
          </Popper>
        </div>
      </div>
      <div v-else-if="!videoIdentStore.token">
        <i18n-t keypath="furtherInformation.tokenExpiredText" tag="p" scope="global">
          <template v-slot:supportLink>
            <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('identSuccess.error.supportLink')">{{ $t("identSuccess.error.support") }}</a>
          </template>
        </i18n-t>
      </div>
      <br>
      <span 
        v-if="videoIdentStore.token && videoIdentStore.isLoading" 
        class="d--loading "
        >
      </span>
    </div>
  </div>
</template>

<script setup>
import CardHeader from "@/components/CardHeader.vue";
import CardBody from "@/components/CardBody.vue";
import ForPers from "@/components/further-information/ForPers.vue";

import { validators } from "@/utils/validators.js";

import { computed, nextTick, onMounted, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { useVideoIdentStore } from "@/store/VideoIdentStore";
const videoIdentStore = useVideoIdentStore();
const errors = videoIdentStore.errors;
const inputs = videoIdentStore.inputs;
const info   = videoIdentStore.info;

import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();
appStore.activeStep = appStore.screenNumbers.furtherInformation;
appStore.header = "furtherInformation.person.title";
if (!videoIdentStore.token) {
  appStore.header = "furtherInformation.tokenExpiredTitle";
}

import { useInputOptionStore } from "@/store/InputOptionStore";
const inputOptionStore = useInputOptionStore();
const inputOptions = inputOptionStore.inputOptions;

// Using the UI, not much can go wrong in this form unless
// not filling out fields.
// Only the phone number is additionally checked against a regex.
// For the other fields, it should be enough to check whether they are set.
const disableSubmit = computed(() => {
  if (videoIdentStore.firstName) {
    return !(
      inputs.forPers.professions[0] &&
      inputs.forPers.salutation &&
      inputs.forPers.phoneCountry &&
      inputs.forPers.phone &&
      /^(?=(?:\D*\d){7,13})\s*/.test(inputs.forPers.phone) &&
      inputs.forPers.language &&
      inputs.forPers.birthdate &&
      inputs.forPers.ackLegalDataSecurityPers
    );
  }
  // Teams only have optional fields.
  return false;
});

// We must display the "association member number" input
// if and only if there is an "association contractSet", for
// example "KOLLEKTIV_ASP".
// (If user chooses "ASP" but does not meet special set requirements,
// they get a "generic" set, and do not have to supply member number.
const displayAssociation = computed(() => {
  return inputOptions?.associations?.de?.some(
    item => item.contractSet === videoIdentStore.contractSet)
});

const handleSubmit = async () => {
  const responseData = await videoIdentStore.handleSubmit();
  appStore.activeStepInvite = 2;
  if (responseData.result) {
    router.push("/video-identification-welcome");
  }
};

onMounted(() => {
  nextTick(() => {
    // Scroll to start of page
    window.scrollTo(0, 0);
    // Select the first input element on the page
    const firstInput = document.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  });
});

onBeforeMount(async () => {
    // Validate token only if user is invited
  if (appStore.isInvitedUser) {
      const isValid = await videoIdentStore.validateToken();

      if (!isValid) { 
        router.push("/ident-failure");
      } 
      if (isValid && videoIdentStore.type === "team") {
        router.push("/video-identification-welcome");
      }
      
    }
});

const unsetError = (errors, event) => {
  // professions etc are array. Their fields have names like
  // professions0, but their error is in places like professions[0].
  const match = event.target?.name.match(/^(\D+)(\d+)$/)
  if (match) {
    const field = match[1];
    const index = parseInt(match[2]);
    errors[field][index] = "";
  } else {
    errors[event.target?.name] = "";
  }
};

_setDefaultValues();
function _setDefaultValues() {
  inputs.forPers.language ??= appStore.currentLanguage;
  inputs.forPers.phoneCountry ??= "CH";
}
</script>
<style scoped>
.padding-zero {
  padding-left: 0px;
}
</style>
