<script setup>
const props = defineProps({
  label: "",
  language: "",
  name: "",
  required: "",
  options: {},
  topic: "",
  error: "",
  selectedValue: "",
});

const emits = defineEmits(['update:selectedValue']);
const handleClick = (event) => {
  emits('update:selectedValue', event.target.value);
};
</script>

<template>
  <div class="form-group">
    <p
      class="custom-control custom-radio "
      v-for="(option) in options?.[language]" :key="option.key"
    >
      <input
        class="custom-control-input"
        type="radio"
        :name
        :id="option.key"
        :value="option.key"
        :checked="option.key === selectedValue"
        @click="handleClick"
      />
      <label class="custom-control-label" :for="option.key">
        {{ option?.[language] || option?.de }}
        <span v-if="option?.amount">
          ({{ $t("contractDetail.inputs.covercardItemPrice",
            {amount: parseFloat(option.amount).toFixed(0)}) }})
        </span>
      </label>
    </p>
  </div>
</template>
