<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name :hint :label />
    <flatpickr
      v-model="internalValue"
      :config="config"
      :name="name"
      :id="name"
      :required
      :class="['form-control', 'max-width']"
    />
    <ErrorSmall v-if="error" :name :error="error" :label="label" />
    <ErrorSmall v-else-if="errorMessage" :name :error="errorMessage" :label="label" />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import 'flatpickr/dist/flatpickr.css';
import flatpickr from 'vue-flatpickr-component';
import ErrorSmall from './ErrorSmall.vue';
import LabelForInput from './LabelForInput.vue';
import { Italian } from 'flatpickr/dist/l10n/it.js';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';

const props = defineProps({
  modelValue: {
    type: [String, Number, Date, Array, null],
    default: null
  },
  hint: String,
  label: String,
  name: String,
  required: Boolean,
  error: String,
  language: String,
});

const emits = defineEmits(['update:modelValue', 'validate']);

const internalValue = computed({
  get: () => props.modelValue,
  set: (val) => emits('update:modelValue', val)
});

const flatpickrKey = ref(0);
let errorMessage = "";

const config = ref({
  locale: German,
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'd.m.Y',
  allowInput: true,
  monthSelectorType: "static",
  onChange(selectedDates, dateStr, instance) {
    if (selectedDates.length === 0 && !dateStr) {
      errorMessage = "Missing property";
      instance.altInput.classList.add('is-invalid');
    } else {
      errorMessage = "";
      instance.altInput.classList.remove('is-invalid');
    }
    emits('validate', {target: {name: props.name, value: dateStr}});
  },
});

// watcher for language changes
watch(() => props.language, (newVal) => {
  if (newVal === 'de') config.value.locale = German;
  else if (newVal === 'it') config.value.locale = Italian;
  else if (newVal === 'fr') config.value.locale = French;
});
</script>

<style>
.flatpickr-day:hover {
  background-color: #FFEFE6;
  border-color: #FFEFE6;
}
.flatpickr-day.nextMonthDay:hover {
  background-color: #FFEFE6;
  border-color: #FFEFE6;
}
.flatpickr-day.prevMonthDay:hover {
  background-color: #FFEFE6;
  border-color: #FFEFE6;
}
.flatpickr-day.selected {
  background-color: #F29800;
  border-color: #F29800;
}
.flatpickr-day.selected:hover {
  background-color: #F29800;
  border-color: #F29800;
}
.flatpickr-day.today {
  border-color: #F29800;
}
.flatpickr-day.today:hover {
  background-color: #F29800;
  border-color: #F29800;
}

.max-width-input {
  max-width: 500px;
  background-color: white;
}
</style>
