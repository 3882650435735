<template>
  <div class="card hreg--card-info d--bg-gray-50 d--mvb-4" @click="focusFirstInput">
    <div class="card d--card-borderless">
      <div class="card-header d--bg-gray-50">
        <div class="row align-items-center">
          <div class="col">
            <p class="m-0">
              <b>{{ formString }}</b>
            </p>
          </div>
          <div class="col-auto">
            <remove-form-button
              :trashCanText="trashCanText"
              :formName="formName"
              :index="index"
            />
          </div>
        </div>
      </div>

      <div id="personalForm" class="card-body">
        <div class="form-group required">
          <div class="row">
            <InputText
              ref="firstInputRef"
              data-test="first-name-input"
              :label="$t('appComponents.inputFirstName')"
              class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"
              :name="'firstName'+formName+index"
              required="required"
              v-model="firstName.value"
              @validate="
                (event) =>
                  identityStore.validateName(
                    formName,
                    index,
                    event.target.value,
                    'firstName'
                  )
              "
              :error="
                formName === 'personalForms'
                  ? identityStore.personalForms[index].firstName.error
                  : identityStore.mpaForms[index].firstName.error
              "
              @change="$emit('updateValue', $event)"
            />
            <InputText
              :label="$t('appComponents.inputLastName')"
              class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"
              :name="'lastName'+formName+index"
              required="required"
              v-model="lastName.value"
              @validate="
                (event) =>
                  identityStore.validateName(
                    formName,
                    index,
                    event.target.value,
                    'lastName'
                  )
              "
              :error="
                formName === 'personalForms'
                  ? identityStore.personalForms[index].lastName.error
                  : identityStore.mpaForms[index].lastName.error
              "
              @change="$emit('updateValue', $event)"
            />
          </div>
        </div>
        <div class="form-group required form-row">
          <div class="col">
            <InputEmail
              id="email"
              :name="'email'+formName+index"
              :label="$t('appComponents.inputEmail')"
              required="required"
              v-model="email.value"
              @input="(event) => lookupValidEmail(event)"
              :error="
                formName === 'personalForms'
                ? identityStore.personalForms[index].email.error
                : identityStore.mpaForms[index].email.error
                "
              @focusout="identityStore.validateEmail(props.formName, props.index, email.value)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import RemoveFormButton from "./RemoveFormButton.vue";
import { useIdentityStore } from "@/store/IdentityStore";
import InputEmail from "@/components/form/InputEmail.vue";
import InputText from "@/components/form/InputText.vue";

const identityStore = useIdentityStore();

const props = defineProps({
  formString: String,
  trashCanText: String,
  index: Number,
  firstName: Object,
  lastName: Object,
  email: Object,
  formName: String,
});

const firstInputRef = ref();
function focusFirstInput (event) {
  // Do nothing if we're clicking inside one of the inputs.
  // Otherwise it is hard to edit 2nd and 3rd input by using the mouse.
  if (event && event?.target?.closest('input')) {
    return;
  }
  if (firstInputRef.value) {
    firstInputRef.value.focusInput();
  }
};

defineExpose({
  focusFirstInput
});

let timerId;
let controller = new AbortController();
async function lookupValidEmail(event) {
  const persEmailValue = event.target.value;
  if (! persEmailValue) {
    return;
  }

  controller.abort();
  controller = new AbortController();
  clearTimeout(timerId);
  timerId = setTimeout(() => {
    identityStore.validateEmail(props.formName, props.index, persEmailValue);
  }, 1000); // 1000ms = 1 second
}
</script>
