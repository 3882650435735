<template>
  <div>
    <div v-if="videoIdentStore.token">
      <div class="hreg--card-info">
        <CardBody>
          <p>
            <i18n-t keypath="videoIdentification.introText" tag="p" scope="global">
              <template v-slot:title>
                <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('membersForm.memExplainLink')">{{ $t("videoIdentification.title") }}</a>
              </template>
            </i18n-t>
          </p>
          <div class="d--bg-gray-50 row-lg">
            <p>
              &nbsp;
              <i class="mdi mdi-information d--text-green-500"></i>
              &nbsp;
              <span class="d--text-green-600">{{
                $t("videoIdentification.availability")
              }}</span>
              &nbsp;
              {{ $t("videoIdentification.businessHours") }}
            </p>
          </div>

          <div class="row d--pvt-4">
            <div class="col col-lg-12">
              <div class="row">
                <div class="col col-md-10 col-xl-<%= $context->{col_size} %>">
                  <div class="hreg--body">
                    <p>
                      <b>{{ $t("videoIdentification.listHeader") }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="d--no-indent">
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.internetAccess") }}</p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.mobilePhone") }}</p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.validId") }} 
              <Popper placement="top" arrow>
                <i class="mdi mdi-information-outline pointer"></i>
                <template #content>
                  <h6>{{ $t("membersForm.memListText.validDocs") }}</h6>
                  <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.idSwiss") }}</p>
                  <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.idForeign") }}</p>
                </template>
              </Popper>
            </p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.mobilePhoneAccess") }}</p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.glnNumber") }}</p>
          </ul>
          <hr class="d--divider-h" />
        </CardBody>
      </div>
      <div class="hreg--buttonbar">
        <span v-if="videoIdentStore.type.startsWith('team')">
          <Popper placement="top" arrow>
            <button type="button" class="btn btn-link">
              {{ $t("appComponents.btnBack") }}
            </button>
            <template #content>
              <h6>{{ $t("furtherInformation.button.back.warningTitle") }}</h6>
              <p>{{ $t("furtherInformation.button.back.warningText") }}</p>
            </template>
          </Popper>
        </span>
        <span v-else>
          <router-link tabindex="-1" to="/further-information">
            <button type="button" class="btn btn-link">
              {{ $t("videoIdentification.button.back") }}
            </button>
          </router-link>
        </span>
        <router-link tabindex="-1" to="/video-identification">
          <button type="submit" class="btn btn-primary">
            {{ $t("videoIdentification.button.forward") }}
          </button>
        </router-link>
      </div>
    </div>
    <div v-else>
      {{ $t("furtherInformation.tokenExpiredText") }}
    </div>
  </div>
</template>

<script setup>
import CardBody from "@/components/CardBody.vue";

import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();
appStore.activeStep = appStore.screenNumbers.videoIdentWelcome;
appStore.header = "videoIdentification.title";

import { useVideoIdentStore } from "@/store/VideoIdentStore";
const videoIdentStore = useVideoIdentStore();

if (!videoIdentStore.token) {
  appStore.header = "furtherInformation.tokenExpiredTitle";
} else {
  videoIdentStore.fetchIdnowIdentUrl();
}
</script>

<style scoped>
.centerIcon {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d--info-popover {
  color: #fff;
  background-color: #fff;
}
.card-body {
  padding: 1rem;
  padding-top: 2rem;
}
.mdi-alert-circle {
  color: #f29800;
}
.pointer{
  cursor: pointer;
}
</style>
