<script setup>
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";
defineProps({
  hint: "",
  label: "",
  minLength: 6,
  maxLength: 320,
  name: "",
  required: "",
  error: "",
});

const value = defineModel();
const emits = defineEmits(['validate']);
</script>

<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name :hint :label />
    <div>
      <div class="input-container">
        <input
          data-test="email-input"
          type="email"
          :class="['form-control', error && 'error-border']"
          :id="name"
          :name
          :minLength
          :maxLength
          :required
          v-model="value"
          @blur="$emit('validate', $event)"
        />
        <DeleteInputIcon v-if="error" @clearInput="value = ''" />
      </div>
    </div>
    <ErrorSmall :name :error :label/>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 500px;
}
</style>
